import React from "react";

import { Link } from "gatsby";
import { documentToHtmlString } from "@contentful/rich-text-html-renderer";

import Layout from "../components/layout";
import SEO from "../components/seo";

function IndexPage({ data }) {
  const entry = data.contentfulPages.entry.json;

  function createMarkup() {
    return { __html: documentToHtmlString(entry) };
  }

  return (
    <Layout>
      <SEO
        title="Artist Information"
        keywords={[`Fu-on Chung`, `Artist`, `Contemporary Artist`, `Painter`]}
      />

      <h1 className="text-center mb-12 text-5xl font-bold tracking-wide">
        <Link to="/">
          <i>Fu-on Chung</i>
        </Link>
      </h1>
      <div dangerouslySetInnerHTML={createMarkup()} />
    </Layout>
  );
}

export default IndexPage;

export const data = graphql`
  query InfoQuery {
    contentfulPages(title: { eq: "Info" }) {
      title
      entry {
        json
      }
    }
  }
`;
